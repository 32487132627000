import React, { useLayoutEffect } from "react";
import { Formik, useFormikContext } from "formik";
import ItemAttributeFormDialog, {
  initialValues,
  validationSchema
} from "./ItemAttributeFormDialog";

const ItemEditForm = props => {
  const { item, ...rest } = props;

  const { resetForm } = useFormikContext();

  useLayoutEffect(() => {
    resetForm({ values: item });
  }, [item, resetForm]);

  if (!item) {
    return null;
  }

  return (
    <ItemAttributeFormDialog
      {...rest}
      item={item}
      title="Edit Package Plan Item"
      loading={false}
    />
  );
};

const FormikItemEditForm = props => {
  const { item, ...rest } = props;

  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  const handleSubmit = (itemValues, formikActions) => {
    if (!item) {
      return;
    }

    props.onClose();

    setFieldTouched("items", true, false);

    setFieldValue(
      `items`,
      values.items.map(entry => {
        if (entry === item) {
          return {
            ...entry,
            ...itemValues
          };
        }

        return {
          ...entry
        };
      })
    );

    formikActions.resetForm(initialValues);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <ItemEditForm {...rest} item={item} items={values.items} />
    </Formik>
  );
};

export default FormikItemEditForm;
