import React from "react";
import { Formik, useFormikContext } from "formik";
import axios from "axios";
import { useApiNotification, useNotification } from "../../../hooks/notification.hook";
import { PROGRESS_TIME_LIMIT } from "../../../config/app.config";
import getAllFieldErrors from "../../../utils/api.util";
import AttributeFormDialog, { createParams, initialValues, validationSchema } from "./AttributeFormDialog";
import moment from "moment";
import FormikStoreBranchSelect from "../../../components/inputs/FormikStoreBranchSelect";

const EditForm = props => {
  const { open, onClose, entity, ...rest } = props;

  const { resetForm } = useFormikContext();

  const [loading, setLoading] = React.useState(false);

  const notifyApiError = useApiNotification();

  React.useLayoutEffect(() => {
    if (!entity) {
      return;
    }

    let active = true;

    setLoading(true);

    axios
      .get(`/api/promotions/${entity.id}`)
      .then(response => {
        const receivedValues = response.data;

        normalizeReceivedValues(receivedValues);

        active && resetForm({ values: receivedValues });
      })
      .catch(error => {
        onClose();

        notifyApiError(
          error.response.status,
          {
            403: {
              message: `Access denied to promotion "${entity.title}"`,
              variant: "error"
            }
          },
          {
            message: `Unable to fetch promotion "${entity.title}"`,
            variant: "error"
          }
        );
      })
      .finally(() => {
        active && setLoading(false);
      });

    return () => {
      active = false;
    };
  }, [entity, resetForm, notifyApiError, onClose]);

  if (!entity) {
    return null;
  }

  return <AttributeFormDialog {...rest} title="Edit Promotion" open={open} onClose={onClose} loading={loading} />;
};

const FormikEditForm = props => {
  const { entity, onOpen, onClose, onSuccess, onBefore, onAfter, ...rest } = props;

  const notify = useNotification();
  const notifyApiError = useApiNotification();

  const handleSubmit = (values, formikActions) => {
    if (!entity) {
      return;
    }

    onClose();

    onBefore(entity);

    const displayProgressTimeoutKey = setTimeout(() => {
      notify(`Updating promotion "${values.title}"`);
    }, PROGRESS_TIME_LIMIT);

    return axios
      .put(`/api/promotions/${values.id}`, createParams(values))
      .then(() => {
        notify(`Promotion "${values.title}" updated`, "success");

        formikActions.resetForm();

        onSuccess();
      })
      .catch(error => {
        // Show the dialog with error messages if client side error, otherwise notify error messages
        if (error.response.status === 400) {
          formikActions.setErrors(getAllFieldErrors(error.response));

          onOpen();
        }

        notifyApiError(
          error.response.status,
          {
            400: {
              message: `Invalid inputs found for promotion "${values.title}"`,
              variant: "warning"
            },
            403: {
              message: `Access denied to update promotion`,
              variant: "error"
            }
          },
          {
            message: `Unable to update promotion "${values.title}"`,
            variant: "error"
          }
        );
      })
      .finally(() => {
        onAfter(entity);

        clearTimeout(displayProgressTimeoutKey);

        formikActions.setSubmitting(false);
      });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
      <EditForm {...rest} entity={entity} onClose={onClose} />
    </Formik>
  );
};

export default FormikEditForm;

const normalizeReceivedValues = receivedValues => {
  receivedValues.title || (receivedValues.title = "");
  receivedValues.description || (receivedValues.description = "");
  receivedValues.files || (receivedValues.files = []);
  receivedValues.startDate || (receivedValues.startDate = null);
  receivedValues.endDate || (receivedValues.endDate = null);
  receivedValues.startTime || (receivedValues.startTime = null);
  receivedValues.endTime || (receivedValues.endTime = null);
  receivedValues.displayStatus || (receivedValues.endTime = false);
  receivedValues.promotionBranches || (receivedValues.promotionBranches = []);

  receivedValues.startDate && (receivedValues.startDate = moment(receivedValues.startDate, "YYYY-MM-DD"));
  receivedValues.endDate && (receivedValues.endDate = moment(receivedValues.endDate, "YYYY-MM-DD"));

  receivedValues.startTime && (receivedValues.startTime = moment(receivedValues.startTime, "HH:mm"));
  receivedValues.endTime && (receivedValues.endTime = moment(receivedValues.endTime, "HH:mm"));
};
