import React from "react";
import ListingTable from "./components/ListingTable";
import RemoveConfirmationDialog from "./components/RemoveConfirmationDialog";
import CreateFormDialog from "./components/CreateFormDialog";
import EntityManagement from "../../components/EntityManagement";
import ManagementPageHeader from "./components/ManagementPageHeader";

const ClosedBusinessDayManagement = () => {
  return (
    <EntityManagement
      pageHeader={ManagementPageHeader}
      createFormDialog={CreateFormDialog}
      removeConfirmationDialog={RemoveConfirmationDialog}
      listingTable={ListingTable}
      api="/api/closedBusinessDays"
    />
  );
};

export default ClosedBusinessDayManagement;
