import axios from "axios";
import securityService from "./security.service";
import axiosConfig from "../config/axios.config";
import store from "../stores";
import { sessionStatusInitial } from "../security/SecurityFilter/actions/sessionStatus.action";

class oauth2Service {
  requestToken = (username, password) => {
    let params = new URLSearchParams();
    params.append("username", username);
    params.append("password", password);
    params.append("grant_type", "password");
    params.append("scope", "api");

    return new Promise((resolve, reject) => {
      // Reject with the response
      axios
        .post("/oauth/token", params, {
          auth: {
            username: "letsfitness",
            password: "letsfitness"
          }
        })
        .then(response => {
          if (this.isTokenResponse(response)) {
            let accessToken = response.data["access_token"];

            securityService.setAccessToken(accessToken);
            axiosConfig.setAuthorizationHeader(accessToken);
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch(error => {
          reject(error.response);
        });
    });
  };

  isTokenResponse = response => {
    let data = response.data;

    return data && typeof data === "object" && "access_token" in data;
  };

  isErrorResponse = response => {
    return (
      response &&
      response.data &&
      typeof response.data === "object" &&
      "error" in response.data &&
      "error_description" in response.data
    );
  };

  handleErrorResponse = response => {
    let status = response.status;

    if (status === 401) {
      // Handle unauthenticated error
      securityService.removeAccessToken();
      axiosConfig.removeAuthorizationHeader();
      store.dispatch(sessionStatusInitial());
    }
  };
}

export default new oauth2Service();
