import React from "react";
import { Formik, useFormikContext } from "formik";
import PromotionBranchAttributeFormDialog, { initialValues, validationSchema } from "./PromotionBranchAttributeFormDialog";

const PromotionBranchCreateForm = props => {
  return <PromotionBranchAttributeFormDialog {...props} title="Create Package Plan Item" loading={false} />;
};

const FormikStoreBranchCreateForm = props => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  const handleSubmit = (itemValues, formikActions) => {
    props.onClose();

    setFieldTouched("promotionBranches", true, false);

    setFieldValue("promotionBranches", [
      ...values.promotionBranches,
      {
        ...itemValues
      }
    ]);

    formikActions.resetForm();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
      <PromotionBranchCreateForm {...props} promotionBranches={values.promotionBranches} />
    </Formik>
  );
};

export default FormikStoreBranchCreateForm;
