import { grey } from "@material-ui/core/colors";

const white = "#FFFFFF";
const black = "#000000";

export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: "#000000",
    main: "#000000",
    light: "#2c2c2c"
  },
  secondary: {
    contrastText: white,
    dark: "#007fbc",
    main: "#00aeef",
    light: "#66e0ff"
  },
  error: {
    contrastText: white,
    dark: "#b20000",
    main: "#ed1c24",
    light: "#ff5f4e"
  },
  background: {
    default: grey[100],
    paper: white
  }
};
