import React from "react";
import { Formik, useFormikContext } from "formik";
import axios from "axios";
import { useApiNotification, useNotification } from "../../../hooks/notification.hook";
import { PROGRESS_TIME_LIMIT } from "../../../config/app.config";
import getAllFieldErrors from "../../../utils/api.util";
import moment from "moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import Grid from "@material-ui/core/Grid";
import FormikKeyboardDatePicker from "../../../components/inputs/FormikKeyboardDatePicker";
import FormikFormDialog from "../../../components/dialogs/FormikFormDialog";
import FormikSwitch from "../../../components/inputs/FormikSwitch";
import * as Yup from "yup";

const useStyles = makeStyles(theme => ({
  sectionLabel: {
    ...theme.typography.h5
  }
}));

const EditForm = props => {
  const { open, onClose, entity, ...rest } = props;

  const classes = useStyles();

  const { resetForm } = useFormikContext();

  const [loading, setLoading] = React.useState(false);

  const notifyApiError = useApiNotification();

  React.useLayoutEffect(() => {
    if (!entity) {
      return;
    }

    let active = true;

    setLoading(true);

    axios
      .get(`/api/customerUsers/packagePlans/${entity.id}`)
      .then(response => {
        const receivedValues = response.data;

        normalizeReceivedValues(receivedValues);

        active && resetForm({ values: receivedValues });
      })
      .catch(error => {
        onClose();

        notifyApiError(
          error.response.status,
          {
            403: {
              message: `Access denied to package plan "${entity.packagePlan.name}"`,
              variant: "error"
            }
          },
          {
            message: `Unable to fetch package plan "${entity.packagePlan.name}"`,
            variant: "error"
          }
        );
      })
      .finally(() => {
        active && setLoading(false);
      });

    return () => {
      active = false;
    };
  }, [entity, resetForm, notifyApiError, onClose]);

  if (!entity) {
    return null;
  }

  return (
    <FormikFormDialog {...rest} title={"Edit Package Plan"} open={open} onClose={onClose} loading={loading}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <FormikKeyboardDatePicker name="effectiveDate" label="Effective Date" />
          </Grid>
          <Grid item xs={6}>
            <FormikKeyboardDatePicker disablePast name="expirationDate" label="Expiration Date" />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormikSwitch
            name="useStatus"
            label="Toggle Package Plan Status"
            description="Indicate if this package plan can be used"
            FormLabelProps={{
              className: classes.sectionLabel
            }}
          />
        </Grid>
      </MuiPickersUtilsProvider>
    </FormikFormDialog>
  );
};

const FormikEditForm = props => {
  const { entity, onOpen, onClose, onSuccess, onBefore, onAfter, ...rest } = props;

  const notify = useNotification();
  const notifyApiError = useApiNotification();

  const handleSubmit = (values, formikActions) => {
    if (!entity) {
      return;
    }

    onClose();

    onBefore(entity);

    const displayProgressTimeoutKey = setTimeout(() => {
      notify(`Updating package plan "${entity.packagePlan.name}"`);
    }, PROGRESS_TIME_LIMIT);

    return axios
      .put(`/api/customerUsers/packagePlans/${values.id}`, createParams(values))
      .then(() => {
        notify(`Package plan "${entity.packagePlan.name}" updated`, "success");

        formikActions.resetForm();

        onSuccess();
      })
      .catch(error => {
        // Show the dialog with error messages if client side error, otherwise notify error messages
        if (error.response.status === 400) {
          formikActions.setErrors(getAllFieldErrors(error.response));

          onOpen();
        }

        notifyApiError(
          error.response.status,
          {
            400: {
              message: `Invalid inputs found`,
              variant: "warning"
            },
            403: {
              message: `Access denied to update package plans`,
              variant: "error"
            }
          },
          {
            message: `Unable to update package plan "${entity.packagePlan.name}"`,
            variant: "error"
          }
        );
      })
      .finally(() => {
        onAfter(entity);

        clearTimeout(displayProgressTimeoutKey);

        formikActions.setSubmitting(false);
      });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} validate={validate}>
      <EditForm {...rest} entity={entity} onClose={onClose} />
    </Formik>
  );
};

export default FormikEditForm;

const validationSchema = Yup.object().shape({
  effectiveDate: Yup.object()
    .nullable(true)
    .required("Required"),
  expirationDate: Yup.object()
    .nullable(true)
    .required("Required")
});

const validate = values => {
  const errors = {};

  if (values.effectiveDate && values.expirationDate) {
    if (values.expirationDate.isBefore(values.effectiveDate)) {
      errors.endDate = "Cannot be earlier than effective date";
    }
  }

  return errors;
};

const initialValues = {
  id: null,
  effectiveDate: null,
  expirationDate: null,
  useStatus: false
};

const createParams = values => {
  const params = new URLSearchParams();

  values.effectiveDate && params.append("effectiveDate", values.effectiveDate.format("YYYY-MM-DD"));
  values.expirationDate && params.append("expirationDate", values.expirationDate.format("YYYY-MM-DD"));
  params.append("useStatus", values.useStatus);
  return params;
};

const normalizeReceivedValues = receivedValues => {
  receivedValues.effectiveDate || (receivedValues.effectiveDate = null);
  receivedValues.expirationDate || (receivedValues.expirationDate = null);
  receivedValues.useStatus || (receivedValues.useStatus = false);

  receivedValues.effectiveDate && (receivedValues.effectiveDate = moment(receivedValues.effectiveDate, "YYYY-MM-DD"));
  receivedValues.expirationDate && (receivedValues.expirationDate = moment(receivedValues.expirationDate, "YYYY-MM-DD"));
};
