import React, { useCallback } from "react";
import axios from "axios";
import FormikLoadableSelect from "./FormikLoadableSelect";
import { PACKAGE_PLAN_TYPE_VALUE_NAME_MAPPING } from "../../constants/app.constant";

const FormikPackagePlanSelect = props => {
  const request = useCallback(() => {
    return axios.get("/api/packagePlans/variants/references", {
      params: { unPaged: true }
    });
  }, []);

  return (
    <FormikLoadableSelect
      {...props}
      request={request}
      getOptionLabel={option =>
        option
          ? `${option.name} (${
              PACKAGE_PLAN_TYPE_VALUE_NAME_MAPPING[option.type]
            })`
          : ""
      }
    />
  );
};

export default FormikPackagePlanSelect;
