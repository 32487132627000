import React from "react";
import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikFormDialog from "../../../components/dialogs/FormikFormDialog";
import FormikTextArea from "../../../components/inputs/FormikTextArea";

export default function(props) {
  const { open, onClose, title, loading, ...rest } = props;
  const [templateContent, setTemplateContent] = React.useState("");
  return (
    <FormikFormDialog {...rest} title={title} open={open} loading={loading} onClose={onClose}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormikTextField required fullWidth label="Subject" name="subject" variant="outlined" margin="dense" />
        </Grid>
        <Grid item xs={12}>
          <FormikTextArea
            fullWidth
            name="content"
            label="Content"
            variant="outlined"
            rows="8"
            onKeyUp={event => setTemplateContent(event.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <label>HTML Preview:</label>
          <hr />
          <div dangerouslySetInnerHTML={{ __html: templateContent }} />
        </Grid>
      </Grid>
    </FormikFormDialog>
  );
}

export const validationSchema = Yup.object().shape({
  subject: Yup.string()
    .max(200, "No more than 200 characters long")
    .required("Required"),
  content: Yup.string().required("Required")
});

export const initialValues = {
  id: null,
  subject: "",
  content: ""
};

export const createParams = values => {
  const params = new URLSearchParams();

  values.subject && params.append("subject", values.subject);
  values.content && params.append("content", values.content);

  return params;
};
