import React from "react";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import FormikKeyboardDatePicker from "../../../components/inputs/FormikKeyboardDatePicker";
import {
  useApiNotification,
  useNotification
} from "../../../hooks/notification.hook";
import { PROGRESS_TIME_LIMIT } from "../../../config/app.config";
import axios from "axios";
import getAllFieldErrors from "../../../utils/api.util";
import { Formik } from "formik";
import moment from "moment";
import fileDownload from "js-file-download";
import FormikTextField from "../../../components/inputs/FormikTextField";
import { GENDER_OPTIONS } from "../../../constants/app.constant";
import FormikAutocomplete from "../../../components/inputs/FormikAutocomplete";
import EmptyEnabledFormikFormDialog from "../../../components/dialogs/EmptyEnabledFormikFormDialog";

const LastAccessCustomerUserReportConfigFormDialog = props => {
  const { open, onClose, ...rest } = props;

  return (
    <EmptyEnabledFormikFormDialog
      {...rest}
      title="Membership Report"
      open={open}
      onClose={onClose}
      loading={false}
    >
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container spacing={1}>
          <Grid item md={6} xs={12}>
            <FormikKeyboardDatePicker
              name="minLastAccessDay"
              label="Start Date"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikKeyboardDatePicker
              name="maxLastAccessDay"
              label="End Date"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikTextField
              fullWidth
              label="Min Age"
              name="minAge"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikTextField
              fullWidth
              label="Max Age"
              name="maxAge"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikAutocomplete
              name="gender"
              margin="dense"
              autoHighlight
              options={GENDER_OPTIONS}
              getOptionLabel={option => option && (option.label || "")}
              TextFieldProps={{
                label: "Gender",
                variant: "outlined",
                fullWidth: true
              }}
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </EmptyEnabledFormikFormDialog>
  );
};

const FormikLastAccessCustomerUserReportConfigFormDialog = props => {
  const { onOpen, onClose, ...rest } = props;

  const notify = useNotification();
  const notifyApiError = useApiNotification();

  const handleSubmit = (values, formikActions) => {
    onClose();

    const displayProgressTimeoutKey = setTimeout(() => {
      notify(`Running membership report`);
    }, PROGRESS_TIME_LIMIT);

    return axios
      .get("/api/reports/lastAccesses", {
        params: {
          minLastAccessDay: values.minLastAccessDay
            ? moment(values.minLastAccessDay).format("YYYY-MM-DD")
            : undefined,
          maxLastAccessDay: values.maxLastAccessDay
            ? moment(values.maxLastAccessDay).format("YYYY-MM-DD")
            : undefined,
          minAge: values.minAge,
          maxAge: values.maxAge,
          gender: values.gender && values.gender.value
        },
        responseType: "blob"
      })
      .then(response => {
        notify(`Membership report completed`, "success");

        formikActions.resetForm();

        fileDownload(response.data, "membership-report.xls");
      })
      .catch(error => {
        // Show the dialog with error messages if client side error, otherwise notify error messages
        if (error.response.status === 400) {
          formikActions.setErrors(getAllFieldErrors(error.response));

          onOpen();
        }

        notifyApiError(
          error.response.status,
          {
            400: {
              message: `Invalid inputs found`,
              variant: "warning"
            },
            403: {
              message: `Access denied to run report`,
              variant: "error"
            }
          },
          {
            message: `Unable to run report`,
            variant: "error"
          }
        );
      })
      .finally(() => {
        clearTimeout(displayProgressTimeoutKey);

        formikActions.setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
    >
      <LastAccessCustomerUserReportConfigFormDialog
        {...rest}
        onClose={onClose}
      />
    </Formik>
  );
};

export default FormikLastAccessCustomerUserReportConfigFormDialog;

const initialValues = {
  minLastAccessDay: null,
  maxLastAccessDay: null
};

const validate = values => {
  const errors = {};

  if (values.minLastAccessDay && values.maxLastAccessDay) {
    if (values.maxLastAccessDay.isBefore(values.minLastAccessDay)) {
      errors.maxLastAccessDay = "Cannot be earlier than start date";
    }
  }

  if (values.minAge && values.maxAge) {
    if (values.maxAge < values.minAge) {
      errors.maxAge = "Cannot be smaller than min age";
    }
  }

  return errors;
};
