import { Formik, useFormikContext } from "formik";
import Grid from "@material-ui/core/Grid";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikAutocomplete from "../../../components/inputs/FormikAutocomplete";
import { BOOLEAN_OPTIONS } from "../../../constants/app.constant";
import React from "react";
import FilterFormButtonGroup from "../../../components/filters/FilterFormButtonGroup";

const FilterForm = props => {
  const { loading } = props;

  const { handleSubmit } = useFormikContext();

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item md={3} xs={6}>
          <FormikTextField
            fullWidth
            label="Username"
            name="username"
            variant="outlined"
            margin="dense"
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <FormikTextField
            fullWidth
            label="First Name"
            name="firstName"
            variant="outlined"
            margin="dense"
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <FormikTextField
            fullWidth
            label="Last Name"
            name="lastName"
            variant="outlined"
            margin="dense"
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <FormikTextField
            fullWidth
            label="Email"
            name="email"
            variant="outlined"
            margin="dense"
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <FormikTextField
            fullWidth
            label="Mobile Phone"
            name="mobilePhone"
            variant="outlined"
            margin="dense"
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <FormikAutocomplete
            name="instructor"
            margin="dense"
            autoHighlight
            options={BOOLEAN_OPTIONS}
            getOptionLabel={option => option && (option.label || "")}
            TextFieldProps={{
              label: "Is Instructor?",
              variant: "outlined",
              fullWidth: true
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FilterFormButtonGroup loading={loading} />
        </Grid>
      </Grid>
    </form>
  );
};

export default function(props) {
  const { loading, onSubmit } = props;

  return (
    <Formik
      initialValues={{
        username: "",
        firstName: "",
        lastName: "",
        email: "",
        mobilePhone: "",
        instructor: null
      }}
      onSubmit={onSubmit}
    >
      <FilterForm loading={loading} />
    </Formik>
  );
}
