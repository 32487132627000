import React from "react";
import { makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  progress: {
    marginRight: theme.spacing(1)
  }
}));

const InfiniteScrollLoader = props => {
  const { className } = props;

  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <CircularProgress size={24} className={classes.progress} />
      <Typography variant="caption">Loading...</Typography>
    </div>
  );
};

export default InfiniteScrollLoader;
