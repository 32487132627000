import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Tooltip
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { useAccessControl } from "../../../hooks/accessControl.hook";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";

const useStyles = makeStyles(theme => ({
  root: {},
  card: {
    position: "relative"
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: "flex-end"
  },
  progress: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  table: {
    whiteSpace: "nowrap"
  },
  columnAction: {
    minWidth: 120
  }
}));

export default function(props) {
  const {
    className,
    entities,
    onView,
    onEdit,
    onEditPackage,
    onEditPackageSuccess,
    onDelete,
    totalCount,
    pageNumber,
    pageSize,
    handlePageNumberChange,
    handlePageSizeChange,
    loading,
    ...rest
  } = props;

  const classes = useStyles();

  const {
    isReadOfferingCategoryAllowed,
    isEditOfferingCategoryAllowed,
    isDeleteOfferingCategoryAllowed
  } = useAccessControl();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography color="textSecondary" gutterBottom variant="body2">
        {totalCount} Records found. Page {pageNumber + 1} of{" "}
        {Math.ceil(totalCount / pageSize)}
      </Typography>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table className={classes.table} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell className={classes.columnAction} align="right">
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {entities.map(entity => (
                    <TableRow hover key={entity.id}>
                      <TableCell>{entity.name}</TableCell>
                      <TableCell align="right">
                        {isReadOfferingCategoryAllowed() && (
                          <Tooltip title="Details" placement="bottom">
                            <IconButton
                              color="default"
                              size="small"
                              onClick={() => onView(entity)}
                            >
                              <VisibilityOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {isEditOfferingCategoryAllowed() && (
                          <Tooltip title="Edit" placement="bottom">
                            <IconButton
                              color="default"
                              size="small"
                              onClick={() => onEdit(entity)}
                              disabled={entity["deleting"] || entity["updating"]}
                            >
                              <EditOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {isDeleteOfferingCategoryAllowed() && (
                          <Tooltip title="Delete" placement="bottom">
                            <IconButton
                              color="default"
                              size="small"
                              onClick={() => onDelete(entity)}
                              disabled={entity["deleting"] || entity["updating"]}
                            >
                              <DeleteOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={totalCount}
            onChangePage={handlePageNumberChange}
            onChangeRowsPerPage={handlePageSizeChange}
            page={pageNumber}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[5, 10, 25, 50]}
          />
        </CardActions>
        {loading && (
          <div className={classes.progress}>
            <CircularProgress />
          </div>
        )}
      </Card>
    </div>
  );
}
