import React from "react";
import ListingTable from "./components/ListingTable";
import DisplayDialog from "./components/DisplayDialog";
import FilterForm from "./components/FilterForm";
import EditFormDialog from "./components/EditFormDialog";
import EntityManagement from "../../components/EntityManagement";
import ManagementPageHeader from "./components/ManagementPageHeader";

const MailTemplateManagement = () => {
  return (
    <EntityManagement
      pageHeader={ManagementPageHeader}
      filterForm={FilterForm}
      editFormDialog={EditFormDialog}
      displayDialog={DisplayDialog}
      listingTable={ListingTable}
      api="/api/mailTemplates"
      initialPageSize={50}
    />
  );
};

export default MailTemplateManagement;
