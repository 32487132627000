import React from "react";
import ListingTable from "./components/ListingTable";
import ManagementPageHeader from "./components/ManagementPageHeader";
import axios from "axios";
import SubEntityManagement from "../../components/SubEntityManagement";

const CustomerUserPurchasedPackagePlanUsageListing = props => {
  const {
    match: {
      params: { id }
    }
  } = props;

  return (
    <SubEntityManagement
      pageHeader={ManagementPageHeader}
      listingTable={ListingTable}
      request={params => {
        return axios.get(`/api/purchasedPackagePlans/customerUsers/${id}`, {
          params: {
            ...params
          }
        });
      }}
      parentApi={`/api/customerUsers/${id}`}
    />
  );
};

export default CustomerUserPurchasedPackagePlanUsageListing;
