import React from "react";
import LabelledStatusBullet from "../components/LabelledStatusBullet";

export const STAFF_USER_ROLE_OWNER = 10000;
export const STAFF_USER_ROLE_ADMINISTRATOR = 10001;
export const STAFF_USER_ROLE_INSTRUCTOR = 10002;
export const STAFF_USER_ROLE_RECEPTION = 10003;
export const STAFF_USER_ROLE_SALES = 10004;

export const STAFF_USER_ROLE_OPTIONS = [
  {
    value: STAFF_USER_ROLE_OWNER,
    label: "Owner"
  },
  {
    value: STAFF_USER_ROLE_ADMINISTRATOR,
    label: "Administrator"
  },
  {
    value: STAFF_USER_ROLE_INSTRUCTOR,
    label: "Instructor"
  },
  {
    value: STAFF_USER_ROLE_SALES,
    label: "Sales"
  }
];

export const STAFF_USER_ROLE_ID_NAME_MAPPING = STAFF_USER_ROLE_OPTIONS.reduce((result, item) => {
  result[item.value] = item.label;
  return result;
}, {});

export const GENDER_OPTIONS = [
  {
    value: "M",
    label: "Male"
  },
  {
    value: "F",
    label: "Female"
  }
];

export const GENDER_CODE_NAME_MAPPING = GENDER_OPTIONS.reduce((result, item) => {
  result[item.value] = item.label;
  return result;
}, {});

export const PACKAGE_PLAN_TYPE_OPTIONS = [
  {
    value: "MONTHLY",
    label: "Monthly"
  },
  {
    value: "PACKAGE",
    label: "Package"
  }
];

export const PACKAGE_PLAN_ITEM_TYPE_OPTIONS = [
  {
    value: "main",
    label: "Main Item"
  },
  {
    value: "additional",
    label: "Additional Item"
  }
];

export const PACKAGE_PLAN_TYPE_VALUE_NAME_MAPPING = PACKAGE_PLAN_TYPE_OPTIONS.reduce((result, item) => {
  result[item.value] = item.label;
  return result;
}, {});

export const BOOLEAN_OPTIONS = [
  {
    value: true,
    label: "Yes"
  },
  {
    value: false,
    label: "No"
  }
];

export const BRANCH_OPTIONS = [
  {
    value: true,
    label: "Open"
  },
  {
    value: false,
    label: "Close"
  }
];

export const RECURRING_TYPE_OPTIONS = [
  {
    value: "WEEKLY",
    label: "Weekly"
  },
  {
    value: "MONTHLY",
    label: "Monthly"
  }
];

export const RECURRING_TYPE_VALUE_NAME_MAPPING = RECURRING_TYPE_OPTIONS.reduce((result, item) => {
  result[item.value] = item.label;
  return result;
}, {});

export const DAY_OF_WEEK_SHORT_NAME = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export const OFFERING_SCHEDULE_SESSION_STATUS_OPTIONS = [
  {
    value: "ACTIVE",
    label: "Active",
    display: <LabelledStatusBullet color="success" label="Active" />
  },
  {
    value: "CANCELLED",
    label: "Cancelled",
    display: <LabelledStatusBullet color="default" label="Cancelled" />
  },
  {
    value: "COMPLETED",
    label: "Completed",
    display: <LabelledStatusBullet color="info" label="Completed" />
  },
  {
    value: "CLOSED",
    label: "Closed",
    display: <LabelledStatusBullet color="default" label="Closed" />
  }
];

export const OFFERING_SCHEDULE_SESSION_STATUS_VALUE_DISPLAY_MAPPING = OFFERING_SCHEDULE_SESSION_STATUS_OPTIONS.reduce((result, item) => {
  result[item.value] = item.display;
  return result;
}, {});

export const PACKAGE_PLAN_STATUS_OPTIONS = [
  {
    value: "ACTIVE",
    label: "Active",
    display: <LabelledStatusBullet color="success" label="Active" />
  },
  {
    value: "EXPIRED",
    label: "Expired",
    display: <LabelledStatusBullet color="default" label="Expired" />
  },
  {
    value: "DISABLED",
    label: "Disabled",
    display: <LabelledStatusBullet color="default" label="Disabled" />
  }
];

export const PACKAGE_PLAN_STATUS_VALUE_DISPLAY_MAPPING = PACKAGE_PLAN_STATUS_OPTIONS.reduce((result, item) => {
  result[item.value] = item.display;
  return result;
}, {});

export const TRANSCRIPT_STATUS_OPTIONS = [
  {
    value: "BOOKED",
    label: "Booked",
    display: <LabelledStatusBullet color="success" label="Booked" />
  },
  {
    value: "WAIT_LISTED",
    label: "Waited",
    display: <LabelledStatusBullet color="warning" label="Waited" />
  },
  {
    value: "CANCELLED",
    label: "Cancelled",
    display: <LabelledStatusBullet color="default" label="Cancelled" />
  },
  {
    value: "PENDING_CONFIRMATION",
    label: "Pending Confirmation",
    display: <LabelledStatusBullet color="primary" label="Pending Confirmation" />
  },
  {
    value: "EXPIRED_CONFIRMATION",
    label: "Cancelled",
    display: <LabelledStatusBullet color="default" label="Expired Confirmation" />
  },
  {
    value: "LATE_CANCELLED",
    label: "Late Cancelled",
    display: <LabelledStatusBullet color="default" label="Late Cancelled" />
  }
];

export const TRANSCRIPT_STATUS_VALUE_DISPLAY_MAPPING = TRANSCRIPT_STATUS_OPTIONS.reduce((result, item) => {
  result[item.value] = item.display;
  return result;
}, {});

export const EMAIL_TEMPLATE_USAGE_DESC = {
  FORGET_PASSWORD: "Forget Password",
  WELCOME: "New Client Welcome",
  BOOK_CONFIRMATION: "Appointment Confirmation",
  CANCEL_CONFIRMATION: "Appointment Cancellation Confirmation",
  WAIT_CONFIRMATION: "Wait List Confirmation",
  SESSION_CANCELLATION: "Class Cancellation",
  SESSION_REMINDER: "Class Reminder",
  WAIT_ACCEPTANCE_REMINDER: "Wait List Acceptance Reminder",
  CLIENT_INFO_UPDATE: "Client Update"
};

export const TRANSCRIPT_DONE_BY_TYPE = {
  SELF: "Self",
  ADMIN: "Admin",
  ADMIN_SESSION_CANCEL: "Admin",
  SYSTEM: "Self"
};

export const ACCESS_CONTROL_LEVEL_YES = 1;

export const ACCESS_CONTROL_LEVEL_READ = 1;
export const ACCESS_CONTROL_LEVEL_UPDATE = 2;
export const ACCESS_CONTROL_LEVEL_UNRESTRICTED = 4;

export const ACCESS_CONTROL_ENTRY_STAFF_USER = "STAFF_USER";
export const ACCESS_CONTROL_ENTRY_CUSTOMER_USER = "CUSTOMER_USER";
export const ACCESS_CONTROL_ENTRY_OFFERING_CATEGORY = "OFFERING_CATEGORY";
export const ACCESS_CONTROL_ENTRY_OFFERING = "OFFERING";
export const ACCESS_CONTROL_ENTRY_OFFERING_SCHEDULE = "OFFERING_SCHEDULE";
export const ACCESS_CONTROL_ENTRY_PACKAGE_PLAN = "PACKAGE_PLAN";
export const ACCESS_CONTROL_ENTRY_NOTIFICATION = "NOTIFICATION";
export const ACCESS_CONTROL_ENTRY_MAIL_TEMPLATE = "MAIL_TEMPLATE";
export const ACCESS_CONTROL_ENTRY_REPORT = "REPORT";
export const ACCESS_CONTROL_ENTRY_SYSTEM_CONFIGURATION = "SYSTEM_CONFIGURATION";
export const ACCESS_CONTROL_ENTRY_CLOSED_BUSINESS_DAY = "CLOSED_BUSINESS_DAY";
export const ACCESS_CONTROL_ENTRY_STORE_BRANCH = "STORE_BRANCH";
export const ACCESS_CONTROL_ENTRY_ENROLL_CLIENT = "ENROLL_CLIENT";
export const ACCESS_CONTROL_ENTRY_PROMOTION = "PROMOTION";
