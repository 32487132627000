import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import LoadableDialog from "../../../components/dialogs/LoadableDialog";
import axios from "axios";
import { useApiNotification } from "../../../hooks/notification.hook";
import moment from "moment";

const DisplayDialog = props => {
  const { open, onClose, entity, ...rest } = props;

  const [detailedEntity, setDetailedEntity] = React.useState({});

  const [loading, setLoading] = React.useState(false);

  const notifyApiError = useApiNotification();

  React.useLayoutEffect(() => {
    if (!entity) {
      return;
    }

    let active = true;

    setLoading(true);

    axios
      .get(`/api/notifications/${entity.id}`)
      .then(response => {
        active && setDetailedEntity(response.data);
      })
      .catch(error => {
        active && onClose();

        active &&
          notifyApiError(
            error.response.status,
            {
              403: {
                message: `Access denied to fetch notification "${entity.name}"`,
                variant: "error"
              }
            },
            {
              message: `Unable to fetch notification "${entity.name}"`,
              variant: "error"
            }
          );
      })
      .finally(() => {
        active && setLoading(false);
      });

    return () => {
      active = false;
    };
  }, [entity, notifyApiError, onClose]);

  if (!entity) {
    return null;
  }

  return (
    <LoadableDialog
      {...rest}
      open={open}
      onClose={onClose}
      loading={loading}
      title="Notification Information"
    >
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>{detailedEntity.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Content</TableCell>
            <TableCell>
              <div
                dangerouslySetInnerHTML={{
                  __html: detailedEntity.content
                }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>External Link</TableCell>
            <TableCell>{detailedEntity.externalLink}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Last Sent Time</TableCell>
            <TableCell>
              {detailedEntity.lastSentTimestamp &&
                moment(detailedEntity.lastSentTimestamp).format(
                  "YYYY-MM-DD HH:mm:ss"
                )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </LoadableDialog>
  );
};

export default DisplayDialog;
