import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

const useStyles = makeStyles(theme => ({
  paper: {
    width: 700,
    maxWidth: "100%"
  },
  title: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  actions: {
    padding: theme.spacing(2, 3)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  progress: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1
  }
}));

export default function(props) {
  const {
    paperClassName,
    title,
    open,
    loading,
    onClose,
    onSubmit,
    onReset,
    submitButtonDisabled,
    resetButtonDisabled,
    children,
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <Dialog
      disableRestoreFocus
      scroll="body"
      maxWidth={false}
      {...rest}
      open={open}
      onClose={onClose}
      PaperProps={{
        className: clsx(classes.paper, paperClassName)
      }}
    >
      <form onSubmit={onSubmit}>
        <DialogTitle className={classes.title} disableTypography>
          <Typography variant="h3">{title}</Typography>
          {onClose && (
            <IconButton className={classes.closeButton} onClick={onClose}>
              <CloseOutlinedIcon />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            color="default"
            onClick={onReset}
            disabled={resetButtonDisabled}
          >
            Reset
          </Button>
          <Button
            variant="outlined"
            type="submit"
            color="secondary"
            disabled={submitButtonDisabled}
          >
            Save
          </Button>
        </DialogActions>
      </form>
      {loading && (
        <div className={classes.progress}>
          <CircularProgress />
        </div>
      )}
    </Dialog>
  );
}
