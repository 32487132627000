import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import CheckIcon from "@material-ui/icons/Check";
import EmptyIcon from "../../../icons/EmptyIcon";

const useStyles = makeStyles(() => ({
  root: {
    height: 160,
    width: "100%"
  },
  iconContainer: {
    display: "flex",
    justifyContent: "flex-start"
  },
  icon: {
    width: 152,
    height: 152
  },
  item: {
    display: "block"
  }
}));

const CustomerUserSelectionForm = props => {
  const { purchasedPackagePlans, onSelect, selected } = props;

  const classes = useStyles();

  return (
    <PerfectScrollbar>
      
      <List className={classes.root}>
        {purchasedPackagePlans && purchasedPackagePlans.length > 0 ? (
          purchasedPackagePlans.map((purchasedPackagePlan, index) => {
            return (
              <React.Fragment key={purchasedPackagePlan.id}>
                <ListItem
                  button
                  dense
                  onClick={() => onSelect(purchasedPackagePlan.id)}
                >
                  <ListItemText
                    primary={purchasedPackagePlan.packagePlan.name}
                    secondary={
                      <React.Fragment>
                        <Typography
                          className={classes.item}
                          component={"span"}
                          variant="body2"
                          color="textSecondary"
                        >
                          {purchasedPackagePlan.packageType}
                        </Typography>
                        <Typography
                          className={classes.item}
                          component={"span"}
                          variant="body2"
                          color="textSecondary"
                        >
                          {purchasedPackagePlan.packagePlan.type}
                        </Typography>
                        <Typography
                          className={classes.item}
                          component={"span"}
                          variant="body2"
                          color="textSecondary"
                        >
                          {purchasedPackagePlan.type}
                        </Typography>
                        <Typography
                          className={classes.item}
                          component={"span"}
                          variant="body2"
                          color="textSecondary"
                        >
                          ExchangeRate: {purchasedPackagePlan.exchange == 0 ? '--' : purchasedPackagePlan.exchange} 
                        </Typography>
                        <Typography
                          className={classes.item}
                          component={"span"}
                          variant="body2"
                          color="textSecondary"
                        >
                          Effective Date: {purchasedPackagePlan.effectiveDate}
                        </Typography>
                        <Typography
                          className={classes.item}
                          component={"span"}
                          variant="body2"
                          color="textSecondary"
                        >
                          Expiration Date: {purchasedPackagePlan.expirationDate}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                  {selected && selected === purchasedPackagePlan.id && (
                    <ListItemSecondaryAction>
                      <CheckIcon color={"secondary"} />
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
                {index !== purchasedPackagePlans.length - 1 && (
                  <Divider component="li" />
                )}
              </React.Fragment>
            );
          })
        ) : (
          <div className={classes.iconContainer}>
            <EmptyIcon className={classes.icon} />
          </div>
        )}
      </List>
    </PerfectScrollbar>
  );
};

export default CustomerUserSelectionForm;
