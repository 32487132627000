import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Tooltip
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { useAccessControl } from "../../../hooks/accessControl.hook";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { retrieveInstructorName } from "../../../utils/app.util";
import { RECURRING_TYPE_VALUE_NAME_MAPPING } from "../../../constants/app.constant";
import { recurringDaysAsString } from "../utils/schedule.util";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import ListOutlinedIcon from "@material-ui/icons/ListOutlined";
import { Link } from "react-router-dom";
import LabelledStatusBullet from "../../../components/LabelledStatusBullet";

const useStyles = makeStyles(theme => ({
  root: {},
  card: {
    position: "relative"
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: "flex-end"
  },
  progress: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  table: {
    whiteSpace: "nowrap"
  },
  columnAction: {
    minWidth: 160
  }
}));

const ListingTable = props => {
  const {
    className,
    entities,
    onView,
    onEdit,
    onEditPackage,
    onEditPackageSuccess,
    onDelete,
    totalCount,
    pageNumber,
    pageSize,
    handlePageNumberChange,
    handlePageSizeChange,
    loading,
    ...rest
  } = props;

  const classes = useStyles();

  const {
    isReadOfferingScheduleAllowed,
    isEditOfferingScheduleAllowed,
    isDeleteOfferingScheduleAllowed
  } = useAccessControl();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography color="textSecondary" gutterBottom variant="body2">
        {totalCount} Records found. Page {pageNumber + 1} of {Math.ceil(totalCount / pageSize)}
      </Typography>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table className={classes.table} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.columnAction}>Actions</TableCell>
                    <TableCell>Instructor</TableCell>
                    <TableCell>Recurring Pattern</TableCell>
                    <TableCell>Recurring Days</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell>Start Time</TableCell>
                    <TableCell>End Time</TableCell>
                    {/*<TableCell>Capacity</TableCell>*/}
                    <TableCell>Online Capacity</TableCell>
                    <TableCell>Wait List Capacity</TableCell>
                    <TableCell>Show to Public</TableCell>
                    <TableCell>Show to Enrollment Figure</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {entities.map(entity => (
                    <TableRow hover key={entity.id}>
                      <TableCell>
                        {isReadOfferingScheduleAllowed() && (
                          <Link to={`/offeringSchedules/${entity.id}/offeringScheduleSessions`}>
                            <Tooltip title="Detail" placement="bottom">
                              <IconButton color="default" size="small">
                                <ListOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                          </Link>
                        )}
                        {isReadOfferingScheduleAllowed() && (
                          <Tooltip title="View" placement="bottom">
                            <IconButton color="default" size="small" onClick={() => onView(entity)}>
                              <VisibilityOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {isEditOfferingScheduleAllowed() && (
                          <Tooltip title="Edit" placement="bottom">
                            <IconButton color="default" size="small" onClick={() => onEdit(entity)} disabled={entity["deleting"] || entity["updating"]}>
                              <EditOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {isDeleteOfferingScheduleAllowed() && (
                          <Tooltip title="Delete" placement="bottom">
                            <IconButton
                              color="default"
                              size="small"
                              onClick={() => onDelete(entity)}
                              disabled={entity["deleting"] || entity["updating"]}>
                              <DeleteOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell>{entity.instructor && retrieveInstructorName(entity.instructor)}</TableCell>
                      <TableCell>{entity.recurringType && RECURRING_TYPE_VALUE_NAME_MAPPING[entity.recurringType]}</TableCell>
                      <TableCell>
                        {entity.recurringOrders &&
                          recurringDaysAsString(
                            entity.recurringType,
                            entity.recurringOrders.sort((a, b) => a - b)
                          )}
                      </TableCell>
                      <TableCell>{entity.startDate && moment(entity.startDate).format("YYYY-MM-DD")}</TableCell>
                      <TableCell>{entity.endDate && moment(entity.endDate).format("YYYY-MM-DD")}</TableCell>
                      <TableCell>{entity.startTime && moment(entity.startTime, "HH:mm").format("HH:mm")}</TableCell>
                      <TableCell>{entity.endTime && moment(entity.endTime, "HH:mm").format("HH:mm")}</TableCell>
                      {/*<TableCell>{entity.capacity}</TableCell>*/}
                      <TableCell>{entity.onlineCapacity}</TableCell>
                      <TableCell>{entity.waitListCapacity}</TableCell>
                      <TableCell>
                        {entity.publicAvailable ? (
                          <LabelledStatusBullet color="success" label="Yes" />
                        ) : (
                          <LabelledStatusBullet color="default" label="No" />
                        )}
                      </TableCell>
                      <TableCell>
                        {entity.figureAvailable ? (
                          <LabelledStatusBullet color="success" label="Yes" />
                        ) : (
                          <LabelledStatusBullet color="default" label="No" />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={totalCount}
            onChangePage={handlePageNumberChange}
            onChangeRowsPerPage={handlePageSizeChange}
            page={pageNumber}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[5, 10, 25, 50]}
          />
        </CardActions>
        {loading && (
          <div className={classes.progress}>
            <CircularProgress />
          </div>
        )}
      </Card>
    </div>
  );
};

export default ListingTable;
