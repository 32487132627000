import React from "react";
import { Button } from "@material-ui/core";
import PageHeader from "../../../components/PageHeader";
import { useAccessControl } from "../../../hooks/accessControl.hook";

const ManagementPageHeader = props => {
  const { onCreate } = props;

  const { isCreateOfferingAllowed } = useAccessControl();

  return (
    <PageHeader
      section="Class Management"
      title="Classes"
      right={
        isCreateOfferingAllowed() && (
          <Button color="secondary" variant="contained" onClick={onCreate}>
            Add Class
          </Button>
        )
      }
    />
  );
};

export default ManagementPageHeader;
