import React from "react";
import ListingTable from "./components/ListingTable";
import DisplayDialog from "./components/DisplayDialog";
import RemoveConfirmationDialog from "./components/RemoveConfirmationDialog";
import FilterForm from "./components/FilterForm";
import CreateFormDialog from "./components/CreateFormDialog";
import EditFormDialog from "./components/EditFormDialog";
import ManagementPageHeader from "./components/ManagementPageHeader";
import EntityManagement from "./EntityManagement";
import SendConfirmationDialog from "./components/SendConfirmationDialog";

const NotificationManagement = () => {
  return (
    <EntityManagement
      pageHeader={ManagementPageHeader}
      filterForm={FilterForm}
      createFormDialog={CreateFormDialog}
      editFormDialog={EditFormDialog}
      displayDialog={DisplayDialog}
      removeConfirmationDialog={RemoveConfirmationDialog}
      sendConfirmationDialog={SendConfirmationDialog}
      listingTable={ListingTable}
      api="/api/notifications"
    />
  );
};

export default NotificationManagement;
