import { Formik, useFormikContext } from "formik";
import Grid from "@material-ui/core/Grid";
import FormikTextField from "../../../components/inputs/FormikTextField";
import React from "react";
import FilterFormButtonGroup from "../../../components/filters/FilterFormButtonGroup";
import FormikOfferingCategorySelect from "../../../components/inputs/FormikOfferingCategorySelect";

const FilterForm = props => {
  const { loading } = props;

  const { handleSubmit } = useFormikContext();

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item md={3} xs={6}>
          <FormikTextField
            fullWidth
            label="Name"
            name="name"
            variant="outlined"
            margin="dense"
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <FormikOfferingCategorySelect
            name="offeringCategory"
            label="Class Category"
            margin="dense"
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FilterFormButtonGroup loading={loading} align="left" />
        </Grid>
      </Grid>
    </form>
  );
};

export default function(props) {
  const { loading, onSubmit } = props;

  return (
    <Formik
      initialValues={{
        name: "",
        offeringCategory: null
      }}
      onSubmit={onSubmit}
    >
      <FilterForm loading={loading} />
    </Formik>
  );
}
