import React from "react";
import ConfirmationDialog from "../../../components/dialogs/ConfirmationDialog";
import {
  useApiNotification,
  useNotification
} from "../../../hooks/notification.hook";
import { PROGRESS_TIME_LIMIT } from "../../../config/app.config";
import axios from "axios";
import _ from "lodash";

const RemoveConfirmationDialog = props => {
  const { open, onClose, onBefore, onAfter, onSuccess, entity } = props;

  const notify = useNotification();
  const notifyApiError = useApiNotification();

  const handleRemove = () => {
    if (!entity) {
      return;
    }

    onClose();

    onBefore(entity.id);

    const truncatedName = _.truncate(entity.name, {
      length: 30,
      separator: " "
    });

    const displayProgressTimeoutKey = setTimeout(() => {
      notify(`Removing class category "${truncatedName}"`);
    }, PROGRESS_TIME_LIMIT);

    axios
      .delete(`/api/offeringCategories/${entity.id}`)
      .then(() => {
        notify(`Class category "${truncatedName}" removed`, "success");

        onSuccess();
      })
      .catch(error => {
        notifyApiError(
          error.response.status,
          {
            403: {
              message: `Access denied to remove class category "${truncatedName}"`,
              variant: "error"
            }
          },
          {
            message: `Unable to remove class category "${truncatedName}"`,
            variant: "error"
          }
        );
      })
      .finally(() => {
        onAfter(entity.id);

        clearTimeout(displayProgressTimeoutKey);
      });
  };

  if (!entity) {
    return null;
  }

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      title="Remove Class Category?"
      message={
        <React.Fragment>
          Class category <b>{entity.name}</b> is going to be removed. You will
          not be able to recover the data once removed.
        </React.Fragment>
      }
      onConfirm={handleRemove}
    />
  );
};

export default RemoveConfirmationDialog;
