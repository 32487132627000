import React, { useCallback, useLayoutEffect, useState } from "react";
import ConfirmationDialog from "../../../components/dialogs/ConfirmationDialog";
import FormDialog, { initialValues, validate } from "./FormDialog";
import FormEditSessionTimeDialog from "./FormEditSessionTimeDialog";
import {
  useApiNotification,
  useNotification
} from "../../../hooks/notification.hook";
import { PROGRESS_TIME_LIMIT } from "../../../config/app.config";
import axios from "axios";
import { retrieveInstructorName } from "../../../utils/app.util";

import { Formik, useFormikContext } from "formik";
import getAllFieldErrors from "../../../utils/api.util";

const RepeatedConfirmationDialog = props => {
  const { open, onClose, onBefore, onAfter, onSuccess, entity, schedule, onSchedule, mode,  ...rest } = props;

  const handleBatchDateRemove = (startDate, endDate, startTime, endTime) => {
    if (!entity) {
      return;
    }

    onClose();

    onSchedule(startDate, endDate, entity, 'BatchRemove', startTime, endTime);

  };

  const handleEditSessionTime = (startDate, endDate, startTime, endTime) => {
    if (!entity) {
      return;
    }

    onClose();

    onSchedule(startDate, endDate, entity, 'EditSessionTime', startTime, endTime);

  };

  var renderMode;

  if(mode.current === 'EditSessionTime')
    renderMode = <FormEditSessionTimeDialog
    {...props}
    schedule={schedule}
    onSchedule={(startDate, endDate, startTime, endTime) => {
        handleEditSessionTime(startDate, endDate, startTime, endTime);
    }}

  />
  else if (mode.current === 'BatchRemove')
    renderMode = <FormDialog
    {...props}
    schedule={schedule}
    onSchedule={(startDate, endDate, startTime, endTime) => {
        handleBatchDateRemove(startDate, endDate, startTime, endTime);
    }}
  />;

  return (
    <div>
      {renderMode}
    </div>

 
  );
};

export default RepeatedConfirmationDialog;

