import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/core";
import { getCustomerUserName } from "../../../utils/app.util";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import CheckIcon from "@material-ui/icons/Check";
import EmptyIcon from "../../../icons/EmptyIcon";

const useStyles = makeStyles(() => ({
  root: {
    height: 160,
    width: "100%"
  },
  iconContainer: {
    display: "flex",
    justifyContent: "flex-start"
  },
  icon: {
    width: 152,
    height: 152
  },
  item: {
    display: "block"
  }
}));

const CustomerUserSelectionForm = props => {
  const { candidates, onSelect, selected } = props;

  const classes = useStyles();

  return (
    <PerfectScrollbar>
      <List className={classes.root}>
        {candidates && candidates.length > 0 ? (
          candidates.map((candidate, index) => {
            return (
              <React.Fragment key={candidate.id}>
                <ListItem button dense onClick={() => onSelect(candidate)}>
                  <ListItemText
                    primary={getCustomerUserName(candidate)}
                    secondary={
                      <React.Fragment>
                        <Typography
                          className={classes.item}
                          component={"span"}
                          variant="body2"
                          color="textSecondary"
                        >
                          {candidate.generatedId}
                        </Typography>
                        <Typography
                          className={classes.item}
                          component={"span"}
                          variant="body2"
                          color="textSecondary"
                        >
                          {candidate.email}
                        </Typography>
                        <Typography
                          className={classes.item}
                          component={"span"}
                          variant="body2"
                          color="textSecondary"
                        >
                          {candidate.mobilePhone}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                  {selected && selected.id === candidate.id && (
                    <ListItemSecondaryAction>
                      <CheckIcon color={"secondary"} />
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
                {index !== candidates.length - 1 && <Divider component="li" />}
              </React.Fragment>
            );
          })
        ) : (
          <div className={classes.iconContainer}>
            <EmptyIcon className={classes.icon} />
          </div>
        )}
      </List>
    </PerfectScrollbar>
  );
};

export default CustomerUserSelectionForm;
