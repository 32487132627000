import React from "react";
import { Formik, useFormikContext } from "formik";
import ItemAttributeFormDialog, { initialValues, validationSchema } from "./ItemAttributeFormDialog";

const ItemCreateForm = props => {
  return <ItemAttributeFormDialog {...props} title="Create Package Plan Item" loading={false} />;
};

const FormikItemCreateForm = props => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const { index } = props;


  const handleSubmit = (itemValues, formikActions) => {
    props.onClose();

    if (index > 0) {
      itemValues["type"] = "ADDITIONAL";
    } else {
      itemValues["type"] = "MAIN";
    }
    setFieldTouched("items", true, false);

    setFieldValue("items", [
      ...values.items,
      {
        ...itemValues
      }
    ]);

    formikActions.resetForm();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
      <ItemCreateForm {...props} items={values.items} />
    </Formik>
  );
};

export default FormikItemCreateForm;
