import React, { useState } from "react";
import { Button, makeStyles, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import Grid from "@material-ui/core/Grid";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useFormikContext } from "formik";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  paper: {
    width: 700,
    maxWidth: "100%"
  },
  title: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  actions: {
    padding: theme.spacing(2, 3)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  progress: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1
  }
}));

export default function(props) {
  const {
    paperClassName,
    title,
    open,
    loading,
    onClose,
    onSubmit,
    onReset,
    submitButtonDisabled,
    resetButtonDisabled,
    children,
    schedule,
    onSchedule,
    ...rest
  } = props;

  const classes = useStyles();

  const [schedules, setSchedules] = React.useState({
    startDate: null,
    endDate: null,
});

const [fieldError, setFieldError] = React.useState({
  startDate: "Please fill in both start date and end date",
  endDate: "Please fill in both start date and end date",
  // startDate: null,
  // endDate: null
});

  return (
    <Dialog
      disableRestoreFocus
      scroll="body"
      maxWidth={false}
      {...rest}
      open={open}
      onClose={onClose}
      PaperProps={{
        className: clsx(classes.paper, paperClassName)
      }}
    >
        <DialogTitle className={classes.title} disableTypography>
          <Typography variant="h3">Remove Class Schedule</Typography>
          {onClose && (
            <IconButton className={classes.closeButton} onClick={onClose}>
              <CloseOutlinedIcon />
            </IconButton>
          )}
        </DialogTitle>
        <MuiPickersUtilsProvider utils={MomentUtils}>
        <Table>
        <TableBody>
        <TableRow>
            <TableCell>Class Name</TableCell>
            <TableCell>{schedule.className}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Instructor</TableCell>
            <TableCell>{schedule.instructor}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Original Start Date</TableCell>
            <TableCell>{schedule.startDate}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Original End Date</TableCell>
            <TableCell>{schedule.endDate}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Remove Start Date</TableCell>
              <TableCell>
                <Grid item xs={9}>
                     <KeyboardDatePicker
                        autoOk
                        inputVariant="outlined"
                        format="DD/MM/YYYY"
                        placeholder={moment(schedule.startDate,"YYYY-MM-DD").format("DD/MM/YYYY")}
                        margin="dense"
                        fullWidth
                        InputAdornmentProps={{ position: "start" }}
                        KeyboardButtonProps={{ size: "small" }}
                        name="startDate"
                        value={schedules.startDate}
                        error={Boolean(fieldError.startDate !== null)}
                        helperText={<span>{fieldError.startDate}</span>}
                        onChange={date => {
                            setSchedules({
                              ...schedules,
                              startDate: date ? date : null
                            });
                              // if((moment(date, 'YYYY-MM-DD').isBefore(moment(schedule.startDate, 'YYYY-MM-DD'))))
                              //   setFieldError({...fieldError, startDate: "Cannot be earlier than original start date"});
                              // else if((moment(date, 'YYYY-MM-DD').isSameOrAfter(moment(schedule.endDate, 'YYYY-MM-DD'))))
                              //   setFieldError({...fieldError, startDate: "Cannot be later or equal to original end date"});
                              // else
                              //   setFieldError({...fieldError, startDate: null});
                              setFieldError({...fieldError, startDate: "Please fill in both start date and end date", endDate: "Please fill in both start date and end date"});
                              if((moment(date, 'YYYY-MM-DD').isSameOrAfter(moment(schedules.endDate, 'YYYY-MM-DD')))) {
                                setFieldError({...fieldError, startDate: "Incorrect date format, end date is earlier than start date", endDate: "Incorrect date format, end date is earlier than start date"});
                              }
                              else if (date != null && schedules.endDate != null) {
                                setFieldError({...fieldError, startDate: null, endDate: null});
                              }
                        }}
                        {...rest}
                    />
                </Grid>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Remove End Date</TableCell>
              <TableCell>
                <Grid item xs={9}>
                     <KeyboardDatePicker
                        autoOk
                        inputVariant="outlined"
                        format="DD/MM/YYYY"
                        placeholder={moment(schedule.endDate,"YYYY-MM-DD").format("DD/MM/YYYY")}
                        margin="dense"
                        fullWidth
                        InputAdornmentProps={{ position: "start" }}
                        KeyboardButtonProps={{ size: "small" }}
                        name="endDate"
                        value={schedules.endDate}
                        helperText={<span>{fieldError.endDate}</span>}
                        onChange={date => {
                            setSchedules({
                              ...schedules,
                              endDate: date ? date : null
                            });
                            // if((moment(date, 'YYYY-MM-DD').isSameOrBefore(moment(schedule.startDate, 'YYYY-MM-DD'))))
                            //   setFieldError({...fieldError, endDate: "Cannot be earlier or equal to original start date"});
                            // else if((moment(date, 'YYYY-MM-DD').isAfter(moment(schedule.endDate, 'YYYY-MM-DD'))))
                            //   setFieldError({...fieldError, endDate: "Cannot be later than original end date"});
                            // else
                            //   setFieldError({...fieldError, endDate: null});
                            setFieldError({...fieldError, startDate: "Please fill in both start date and end date", endDate: "Please fill in both start date and end date"});
                            if((moment(date, 'YYYY-MM-DD').isSameOrBefore(moment(schedules.startDate, 'YYYY-MM-DD')))) {
                              setFieldError({...fieldError, startDate: "Incorrect date format, end date is earlier than start date", endDate: "Incorrect date format, end date is earlier than start date"});
                            }
                            else if (date != null && schedules.startDate != null) {
                              setFieldError({...fieldError, startDate: null, endDate: null});
                            }
                        }}
                        {...rest}
                        error={Boolean(fieldError.endDate !== null)}
                    />
                </Grid>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      </MuiPickersUtilsProvider>
         
        <DialogActions className={classes.actions}>
          <Button
            color="default"
            onClick={() => {
              setSchedules({
                ...schedules,
                startDate: null,
                endDate: null
              });
            }}
            disabled={schedules.startDate === null && schedules.endDate === null}
          >
            Reset
          </Button>
          <Button
            variant="outlined"
            type="submit"
            color="secondary"
            disabled={fieldError.startDate !== null || fieldError.endDate !== null || schedules.startDate === null || schedules.endDate === null ||
              moment(schedules.startDate, "DD/MM/YYYY").format("DD/MM/YYYY") === schedules.startDate || 
              moment(schedules.endDate, "DD/MM/YYYY").format("DD/MM/YYYY") === schedules.endDate}
            onClick={() => {
                onSubmit(schedule); 
                var startDate = moment(schedules.startDate, "YYYY-MM-DD").format("YYYY-MM-DD");
                var endDate = moment(schedules.endDate, "YYYY-MM-DD").format("YYYY-MM-DD"); 
                var startTime = schedule.startTime;
                var endTime = schedule.endTime;
                onSchedule(startDate, endDate, startTime, endTime);
                setSchedules({...schedules, startDate: null, endDate: null})
            }}
          >
            Delete
          </Button>
        </DialogActions>
      {loading && (
        <div className={classes.progress}>
          <CircularProgress />
        </div>
      )}
    </Dialog>
  );
}

