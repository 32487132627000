import React, { useLayoutEffect } from "react";
import { Formik, useFormikContext } from "formik";
import PromotionBranchAttributeFormDialog, { initialValues, validationSchema } from "./PromotionBranchAttributeFormDialog";

const PromotionBranchEditForm = props => {
  const { promotionBranch, ...rest } = props;

  const { resetForm } = useFormikContext();

  useLayoutEffect(() => {
    resetForm({ values: promotionBranch });
  }, [promotionBranch, resetForm]);

  if (!promotionBranch) {
    return null;
  }

  return <PromotionBranchAttributeFormDialog {...rest} promotionBranch={promotionBranch} title="Edit Promotion Store Branch" loading={false} />;
};

const FormikStoreBranchEditForm = props => {
  const { promotionBranch, ...rest } = props;

  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  const handleSubmit = (itemValues, formikActions) => {
    if (!promotionBranch) {
      return;
    }

    props.onClose();

    setFieldTouched("promotionBranches", true, false);

    setFieldValue(
      `promotionBranches`,
      values.promotionBranches.map(entry => {
        if (entry === promotionBranch) {
          return {
            ...entry,
            ...itemValues
          };
        }

        return {
          ...entry
        };
      })
    );

    formikActions.resetForm(initialValues);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
      <PromotionBranchEditForm {...rest} promotionBranch={promotionBranch} promotionBranches={values.promotionBranches} />
    </Formik>
  );
};

export default FormikStoreBranchEditForm;
