import React, { useCallback, useLayoutEffect, useState } from "react";
import FilterForm from "./components/FilterForm";
import Frame from "../../components/Frame";
import { makeStyles } from "@material-ui/core";
import PageHeader from "../../components/PageHeader";
import axios from "axios";
import ScheduleSessionTable from "./components/ScheduleSessionTable";
import moment from "moment";
import ErrorFilterResultPlaceholder from "../../components/filters/ErrorFilterResultPlaceholder";
import EmptyFilterResultPlaceholder from "../../components/filters/EmptyFilterResultPlaceholder";
import CircularProgress from "@material-ui/core/CircularProgress";
import Toolbar from "./components/Toolbar";
import { dateComparator } from "../../utils/app.util";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import numeral from "numeral";
import RemoveConfirmationDialog from "../OfferingScheduleSessionManagement/components/RemoveConfirmationDialog";
import EditFormDialog from "./components/EditFormDialog";
import RemoveBatchConfirmationDialog from "./components/RemoveBatchConfirmationDialog";
import EditConfirmationDialog from "./components/EditConfirmationDialog";
import EditSessionTimeConfirmationDialog from "./components/EditSessionTimeConfirmationDialog";
import RepeatedConfirmationDialog from "./components/RepeatedConfirmationDialog";
import { useApiNotification, useNotification } from "../../hooks/notification.hook";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column"
  },
  emptyFilterResult: {
    flex: "1 1 auto"
  },
  errorFilterResult: {
    flex: "1 1 auto"
  },
  progress: {
    flex: "1 1 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  toolbar: {
    marginTop: theme.spacing(2)
  },
  statsData: {
    minWidth: 96
  }
}));

const OfferingScheduleSessionList = () => {
  const classes = useStyles();

  /*
  Status for loading listing table
  */

  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingTableError, setLoadingTableError] = useState(false);
  const [showTableWhileLoading, setShowTableWhileLoading] = useState(false);
  const [schedule, setSchedule] = React.useState({
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
    className: null,
    instructor: null,
    instructorId: null,
    capacity: null,
    onlineCapacity: "",
    waitListCapacity: "",
    publicAvailable: false,
    figureAvailable: true,
    storeBranch: null
  });
  const [cancelSession, setCancelSession] = React.useState({
    sessionList: null
  });

  const [scheduleRemove, setScheduleRemove] = React.useState({
    startDate: null,
    endDate: null
  });

  const [sessionTime, setSessionTime] = React.useState({
    startTime: null,
    endTime: null
  });

  const [mode, setMode] = React.useState({
    current: null
  });

  const [editValues, setEditValues] = React.useState({
    values: null
  });

  const notifyApiError = useApiNotification();

  /*
  Day filters
   */

  const [filters, setFilters] = useState({
    currentDay: moment().startOf("day"),
    startDay: moment().startOf("day"),
    daySize: 1,
    view: "timeGridDay",
    offeringCategory: null,
    instructor: null,
    storeBranch: null
  });

  const handleCurrentDayChange = value => {
    setFilters({
      ...filters,
      currentDay: value,
      startDay: value,
      daySize: 1,
      view: "timeGridDay"
    });
  };

  const handleValueChange = (name, value) => {
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const handleDatePrev = () => {
    if (filters.view === "timeGridDay") {
      const nextDay = moment(filters.currentDay).subtract(1, "days");

      setFilters({
        ...filters,
        currentDay: nextDay,
        startDay: nextDay,
        daySize: 1
      });
    } else if (filters.view === "timeGridWeek") {
      const nextDay = moment(filters.currentDay).subtract(1, "weeks");

      setFilters({
        ...filters,
        currentDay: nextDay,
        startDay: nextDay.startOf("week"),
        daySize: 7
      });
    } else {
      const startOfCurrentMonth = moment(filters.currentDay).startOf("month");
      const endOfPrevMonth = moment(startOfCurrentMonth).subtract(1, "days");
      const startOfPrevMonth = moment(endOfPrevMonth).startOf("month");

      const currentDayOfMonth = moment(filters.currentDay).date();

      let nextDay = moment(startOfPrevMonth).add(currentDayOfMonth - 1, "days");
      if (nextDay.isAfter(endOfPrevMonth)) {
        nextDay = endOfPrevMonth;
      }

      setFilters({
        ...filters,
        currentDay: nextDay,
        startDay: startOfPrevMonth,
        daySize: nextDay.daysInMonth()
      });
    }
  };

  const handleDateToday = () => {
    setFilters({
      ...filters,
      currentDay: moment().startOf("day"),
      startDay: moment().startOf("day"),
      daySize: 1,
      view: "timeGridDay"
    });
  };

  const handleDateNext = () => {
    if (filters.view === "timeGridDay") {
      const nextDay = moment(filters.currentDay).add(1, "days");

      setFilters({
        ...filters,
        currentDay: nextDay,
        startDay: nextDay,
        daySize: 1
      });
    } else if (filters.view === "timeGridWeek") {
      const nextDay = moment(filters.currentDay).add(1, "weeks");

      setFilters({
        ...filters,
        currentDay: nextDay,
        startDay: nextDay.startOf("week"),
        daySize: 7
      });
    } else {
      const endOfCurrentMonth = moment(filters.currentDay).endOf("month");
      const startOfNextMonth = moment(endOfCurrentMonth).add(1, "days");
      const endOfNextMonth = moment(startOfNextMonth).endOf("month");

      const currentDayOfMonth = moment(filters.currentDay).date();

      let nextDay = moment(startOfNextMonth).add(currentDayOfMonth - 1, "days");
      if (nextDay.isAfter(endOfNextMonth)) {
        nextDay = endOfNextMonth;
      }

      setFilters({
        ...filters,
        currentDay: nextDay,
        startDay: startOfNextMonth,
        daySize: nextDay.daysInMonth()
      });
    }
  };

  const handleViewChange = view => {
    if (view === filters.view) {
      return;
    }

    if (view === "timeGridDay") {
      setFilters({
        ...filters,
        startDay: moment(filters.currentDay),
        daySize: 1,
        view: view
      });
    } else if (view === "timeGridWeek") {
      setFilters({
        ...filters,
        startDay: moment(filters.currentDay).startOf("week"),
        daySize: 7,
        view: view
      });
    } else {
      setFilters({
        ...filters,
        startDay: moment(filters.currentDay).startOf("month"),
        daySize: moment(filters.currentDay).daysInMonth(),
        view: view
      });
    }
  };

  const handleEdit = session => {
    setEditFormDialogState(prevState => ({
      ...prevState,
      entity: session,
      open: true
    }));
  };

  const handleRemove = session => {
    setRemoveConfirmationDialogState(prevState => ({
      ...prevState,
      entity: session,
      open: true
    }));
  };

  const handleEditSessionTime = session => {
    axios
      .get(`/api/offeringSchedules/${session.offeringSchedule.id}`)
      .then(response => {
        setSchedule(prevState => ({
          ...prevState,
          startDate: response.data.startDate,
          endDate: response.data.endDate,
          startTime: moment(response.data.startTime, "HH:mm").format("HH:mm"),
          endTime: moment(response.data.endTime, "HH:mm").format("HH:mm"),
          className: response.data.offering.name,
          instructor: response.data.instructor.firstName + " " + response.data.instructor.lastName,
          instructorId: response.data.instructor.id,
          // capacity: response.data,
          onlineCapacity: response.data.onlineCapacity,
          waitListCapacity: response.data.waitListCapacity,
          publicAvailable: response.data.publicAvailable,
          figureAvailable: response.data.figureAvailable,
          storeBranch: response.data.storeBranch
        }));
      })
      .catch(error => {
        notifyApiError(
          error.response.status,
          {
            403: {
              message: `Access denied to get class schedule`,
              variant: "error"
            }
          },
          {
            message: `Unable to get class schedule`,
            variant: "error"
          }
        );
      });

    axios
      .get(`/api/offeringScheduleSessions`, {
        params: {
          //   ...params,
          offeringSchedule: session.offeringSchedule.id,
          size: 10000000
        }
      })
      .then(response => {
        setCancelSession({
          ...cancelSession,
          sessionList: response.data["content"]
        });
      })
      .catch(error => {
        notifyApiError(
          error.response.status,
          {
            403: {
              message: `Access denied to get class schedule sessions`,
              variant: "error"
            }
          },
          {
            message: `Unable to get class schedule sessions`,
            variant: "error"
          }
        );
      });

    setRepeatedConfirmationDialogState(prevState => ({
      ...prevState,
      entity: session,
      open: true
    }));
    setMode({ current: "EditSessionTime" });
  };

  const handleRepeated = session => {
    axios
      .get(`/api/offeringSchedules/${session.offeringSchedule.id}`)
      .then(response => {
        setSchedule(prevState => ({
          ...prevState,
          startDate: response.data.startDate,
          endDate: response.data.endDate,
          startTime: moment(response.data.startTime, "HH:mm").format("HH:mm"),
          endTime: moment(response.data.endTime, "HH:mm").format("HH:mm"),
          className: response.data.offering.name,
          instructor: response.data.instructor.firstName + " " + response.data.instructor.lastName,
          instructorId: response.data.instructor.id,
          // capacity: response.data,
          onlineCapacity: response.data.onlineCapacity,
          waitListCapacity: response.data.waitListCapacity,
          publicAvailable: response.data.publicAvailable,
          figureAvailable: response.data.figureAvailable,
          storeBranch: response.data.storeBranch
        }));
      })
      .catch(error => {
        notifyApiError(
          error.response.status,
          {
            403: {
              message: `Access denied to get class schedule`,
              variant: "error"
            }
          },
          {
            message: `Unable to get class schedule`,
            variant: "error"
          }
        );
      });

    axios
      .get(`/api/offeringScheduleSessions`, {
        params: {
          //   ...params,
          offeringSchedule: session.offeringSchedule.id,
          size: 10000000
        }
      })
      .then(response => {
        setCancelSession({
          ...cancelSession,
          sessionList: response.data["content"]
        });
      })
      .catch(error => {
        notifyApiError(
          error.response.status,
          {
            403: {
              message: `Access denied to get class schedule sessions`,
              variant: "error"
            }
          },
          {
            message: `Unable to get class schedule sessions`,
            variant: "error"
          }
        );
      });

    setRepeatedConfirmationDialogState(prevState => ({
      ...prevState,
      entity: session,
      open: true
    }));
    setMode({ current: "BatchRemove" });
  };

  /*
  Data to display
   */

  const [sessionGroupByDay, setSessionGroupByDay] = useState({});

  useLayoutEffect(() => {
    let active = true;

    setLoadingTable(true);
    setLoadingTableError(false);

    const params = {
      ...filters
    };

    axios
      .post("/api/offeringScheduleSessions/actions/query", createParams(params))
      .then(response => {
        if (active) {
          const data = response.data || {};

          setSessionGroupByDay(data);

          setLoadingTableError(false);
        }
      })
      .catch(() => {
        active && setLoadingTableError(true);
      })
      .finally(() => {
        active && setLoadingTable(false);
      });

    return () => {
      active = false;
    };
  }, [filters]);

  const days = Object.keys(sessionGroupByDay).sort(dateComparator);

  const [statsStatus, setStatsStatus] = useState({
    sessionCount: null,
    totalCapacity: null,
    totalBookedCount: null,
    loading: false,
    error: false
  });

  useLayoutEffect(() => {
    let active = true;

    setStatsStatus(prevState => ({
      ...prevState,
      loading: true,
      error: false
    }));

    axios
      .get("/api/stats/offeringScheduleSessions/periodUsage", {
        params: {
          startDate: moment(filters.startDay).format("YYYY-MM-DD"),
          daySize: filters.daySize
        }
      })
      .then(response => {
        if (active) {
          const data = response.data || {};

          setStatsStatus(prevState => ({
            ...prevState,
            loading: false,
            error: false,
            ...data
          }));
        }
      })
      .catch(() => {
        active &&
          setStatsStatus(prevState => ({
            ...prevState,
            loading: false,
            error: true
          }));
      });

    return () => {
      active = false;
    };
  }, [filters.daySize, filters.startDay]);

  const resultTables = days.map(day => (
    <ScheduleSessionTable
      key={day}
      day={day}
      sessions={sessionGroupByDay[day]}
      onDelete={handleRemove}
      onEdit={handleEdit}
      onEditSessionTime={handleEditSessionTime}
      onDeleteBatch={handleRepeated}
    />
  ));

  /* Edit Form */

  const [editFormDialogState, setEditFormDialogState] = useState({
    open: false
  });

  const handleEditFormDialogOpen = React.useCallback(() => {
    setEditFormDialogState(prevState => ({
      ...prevState,
      open: true
    }));
  }, []);

  const handleEditFormDialogClose = React.useCallback(() => {
    setEditFormDialogState(prevState => ({
      ...prevState,
      open: false
    }));
  }, []);

  const editFormDialog = EditFormDialog && (
    <EditFormDialog
      {...editFormDialogState}
      onOpen={handleEditFormDialogOpen}
      onClose={handleEditFormDialogClose}
      onSuccess={(value, session) => {
        setEditFormDialogState(prevState => ({
          ...prevState,
          entity: null,
          open: false
        }));

        setEditConfirmationDialogState(prevState => ({
          ...prevState,
          entity: session,
          open: true
        }));

        setEditValues({
          ...editValues,
          values: value
        });
      }}
      onBefore={targetEntity => {}}
      onAfter={targetEntity => {}}
    />
  );

  const [editConfirmationDialogState, setEditConfirmationDialogState] = React.useState({
    open: false
  });

  const handleEditConfirmationDialogOpen = React.useCallback(() => {
    setEditConfirmationDialogState(prevState => ({
      ...prevState,
      open: true
    }));
  }, []);

  const handleEditConfirmationDialogClose = React.useCallback(() => {
    setEditConfirmationDialogState(prevState => ({
      ...prevState,
      open: false
    }));
  }, []);

  const editConfirmationDialog = EditConfirmationDialog && (
    <EditConfirmationDialog
      {...editConfirmationDialogState}
      onOpen={handleEditConfirmationDialogOpen}
      onClose={handleEditConfirmationDialogClose}
      onSuccess={() => {
        setLoadingTable(false);
        setShowTableWhileLoading(true);
        setFilters({ ...filters });
      }}
      scheduleRemove={scheduleRemove}
      schedule={schedule}
      sessionList={cancelSession}
      values={editValues.values}
    />
  );

  /* Remove Confirmation Dialog */

  const [removeConfirmationDialogState, setRemoveConfirmationDialogState] = React.useState({
    open: false
  });

  const handleRemoveConfirmationDialogClose = React.useCallback(() => {
    setRemoveConfirmationDialogState(prevState => ({
      ...prevState,
      open: false
    }));
  }, []);

  const removeConfirmationDialog = RemoveConfirmationDialog && (
    <RemoveConfirmationDialog
      {...removeConfirmationDialogState}
      onClose={handleRemoveConfirmationDialogClose}
      onSuccess={() => {
        setLoadingTable(false);
        setShowTableWhileLoading(true);
        setFilters({ ...filters });

        let active = true;

        setStatsStatus(prevState => ({
          ...prevState,
          loading: true,
          error: false
        }));

        axios
          .get("/api/stats/offeringScheduleSessions/periodUsage", {
            params: {
              startDate: moment(filters.startDay).format("YYYY-MM-DD"),
              daySize: filters.daySize
            }
          })
          .then(response => {
            if (active) {
              const data = response.data || {};

              setStatsStatus(prevState => ({
                ...prevState,
                loading: false,
                error: false,
                ...data
              }));
            }
          })
          .catch(() => {
            active &&
              setStatsStatus(prevState => ({
                ...prevState,
                loading: false,
                error: true
              }));
          });

        return () => {
          active = false;
        };
      }}
      onBefore={targetEntity => {}}
      onAfter={targetEntity => {}}
    />
  );

  /* Remove Batch Confirmation Dialog */

  const [repeatedConfirmationDialogState, setRepeatedConfirmationDialogState] = React.useState({
    open: false
  });

  const handleRepeatedConfirmationDialogClose = React.useCallback(() => {
    setRepeatedConfirmationDialogState(prevState => ({
      ...prevState,
      open: false
    }));
  }, []);

  const repeatedConfirmationDialog = RepeatedConfirmationDialog && (
    <RepeatedConfirmationDialog
      {...repeatedConfirmationDialogState}
      onClose={handleRepeatedConfirmationDialogClose}
      onSubmit={session => {}}
      mode={mode}
      schedule={schedule}
      onSchedule={(startDate, endDate, session, form, startTime, endTime) => {
        setLoadingTable(false);
        setShowTableWhileLoading(true);
        setFilters({ ...filters });
        setScheduleRemove({ ...scheduleRemove, startDate: startDate, endDate: endDate });
        setRepeatedConfirmationDialogState(prevState => ({
          ...prevState,
          open: false
        }));
        if (form === "BatchRemove")
          setRemoveBatchConfirmationDialogState(prevState => ({
            ...prevState,
            entity: session,
            open: true
          }));
        else if (form === "EditSessionTime") {
          setEditSessionTimeConfirmationDialogState(prevState => ({
            ...prevState,
            entity: session,
            open: true
          }));
          setSessionTime({ ...sessionTime, startTime: startTime, endTime: endTime });
        }
      }}
    />
  );

  const [removeBatchConfirmationDialogState, setRemoveBatchConfirmationDialogState] = React.useState({
    open: false
  });

  const handleRemoveBatchConfirmationDialogClose = React.useCallback(() => {
    setRemoveBatchConfirmationDialogState(prevState => ({
      ...prevState,
      open: false
    }));
  }, []);

  const removeBatchConfirmationDialog = RemoveBatchConfirmationDialog && (
    <RemoveBatchConfirmationDialog
      {...removeBatchConfirmationDialogState}
      onClose={handleRemoveBatchConfirmationDialogClose}
      onSuccess={() => {
        setLoadingTable(false);
        setShowTableWhileLoading(true);
        setFilters({ ...filters });
      }}
      scheduleRemove={scheduleRemove}
      schedule={schedule}
      sessionList={cancelSession}
    />
  );

  const [editSessionTimeConfirmationDialogState, setEditSessionTimeConfirmationDialogState] = React.useState({
    open: false
  });

  const handleEditSessionTimeConfirmationDialogClose = React.useCallback(() => {
    setEditSessionTimeConfirmationDialogState(prevState => ({
      ...prevState,
      open: false
    }));
  }, []);

  const editSessionTimeConfirmationDialog = EditSessionTimeConfirmationDialog && (
    <EditSessionTimeConfirmationDialog
      {...editSessionTimeConfirmationDialogState}
      onClose={handleEditSessionTimeConfirmationDialogClose}
      onSuccess={() => {
        setLoadingTable(false);
        setShowTableWhileLoading(true);
        setFilters({ ...filters });
      }}
      scheduleRemove={scheduleRemove}
      schedule={schedule}
      sessionList={cancelSession}
      sessionTime={sessionTime}
    />
  );

  const displayDotsWhenLoading = useCallback(
    content => {
      if (statsStatus.loading) {
        return "loading...";
      } else {
        return content;
      }
    },
    [statsStatus.loading]
  );

  const totalCapacityUtilization =
    numeral(100 * statsStatus.totalCapacity ? (statsStatus.totalBookedCount / statsStatus.totalCapacity) * 100 : 0).format("0.00") + "%";

  const averageBookedCount = numeral(statsStatus.totalBookedCount / filters.daySize).format("0.00");

  return (
    <Frame className={classes.root}>
      <PageHeader
        section="Overview"
        title="Upcoming Class Schedules"
        right={
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>Total classes</TableCell>
                <TableCell className={classes.statsData} align="right">
                  {displayDotsWhenLoading(statsStatus.sessionCount)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Total attendance</TableCell>
                <TableCell className={classes.statsData} align="right">
                  {displayDotsWhenLoading(`(${statsStatus.totalBookedCount}, ${statsStatus.totalCapacity})`)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Total capacity utilization</TableCell>
                <TableCell className={classes.statsData} align="right">
                  {displayDotsWhenLoading(totalCapacityUtilization)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Average</TableCell>
                <TableCell className={classes.statsData} align="right">
                  {displayDotsWhenLoading(averageBookedCount)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        }
      />
      <FilterForm loading={loadingTable} onValueChange={handleValueChange} onCurrentDayChange={handleCurrentDayChange} values={filters} />
      <Toolbar
        className={classes.toolbar}
        date={filters.currentDay}
        view={filters.view}
        onDatePrev={handleDatePrev}
        onDateToday={handleDateToday}
        onDateNext={handleDateNext}
        onViewChange={handleViewChange}
      />
      {loadingTableError ? (
        <ErrorFilterResultPlaceholder className={classes.errorFilterResult} />
      ) : loadingTable ? (
        showTableWhileLoading ? (
          resultTables
        ) : (
          <div className={classes.progress}>
            <CircularProgress />
          </div>
        )
      ) : days && days.length > 0 ? (
        resultTables
      ) : (
        <EmptyFilterResultPlaceholder className={classes.emptyFilterResult} />
      )}
      {removeBatchConfirmationDialog}
      {editSessionTimeConfirmationDialog}
      {repeatedConfirmationDialog}
      {removeConfirmationDialog}
      {editFormDialog}
      {editConfirmationDialog}
    </Frame>
  );
};

export default OfferingScheduleSessionList;

const createParams = values => {
  const params = new URLSearchParams();

  values.startDay && params.append("startDay", values.startDay.format("YYYY-MM-DD"));

  values.daySize && params.append("daySize", values.daySize);

  values.offeringCategory && params.append(`offeringCategories[0]`, values.offeringCategory.id);

  values.instructor && params.append(`instructors[0]`, values.instructor.id);

  values.storeBranch && params.append(`storeBranches[0]`, values.storeBranch.id);

  return params;
};
