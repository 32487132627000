import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";
import { PACKAGE_PLAN_TYPE_VALUE_NAME_MAPPING } from "../../../constants/app.constant";

const useStyles = makeStyles(theme => ({
  root: {},
  card: {
    position: "relative"
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: "flex-end"
  },
  progress: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  table: {
    whiteSpace: "nowrap"
  },
  columnAction: {
    minWidth: 120
  },
  packageTitle: {
    fontWeight: "bold",
    margin: "5px"
  },
  textCenter: {
    textAlign: "center"
  }
}));

const ListingTable = props => {
  const {
    className,
    entities,
    onView,
    onEdit,
    onEditPackage,
    onEditPackageSuccess,
    onDelete,
    totalCount,
    pageNumber,
    pageSize,
    handlePageNumberChange,
    handlePageSizeChange,
    loading,
    ...rest
  } = props;

  const classes = useStyles();

  if (entities) {
    entities.map(entity => {
      var mainBranch = [];
      var additionalBranch = [];
      var totalBranch = [];

      entity.items.map(item => {
        if (item.type == "MAIN") {
          var tempMain = item;
          tempMain.calculatedCount = parseInt(item.usedPurchasedPackagePlanItem, 10);
          mainBranch.push(item);
        }
        else if (item.type == "ADDITIONAL"){
          additionalBranch.push(item);
        }
      })

      entity.mainBranch = mainBranch;
      entity.additionalBranch = additionalBranch;
      if (additionalBranch.length > 0) {
        for (var i = 0; i < entity.items.length; i++)
          if (entity.items[i].totalTicketCount > 0)
            totalBranch.push(entity.items[i]);
      }
      entity.totalBranch = totalBranch;
    })

  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography color="textSecondary" gutterBottom variant="body2">
        {totalCount} Records found. Page {pageNumber + 1} of{" "}
        {Math.ceil(totalCount / pageSize)}
      </Typography>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table className={classes.table} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Package Plan</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Effective Date</TableCell>
                    <TableCell>Expiration Date</TableCell>
                    <TableCell>Balance</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {entities.map(entity => (
                    <TableRow hover key={entity.id}>
                      <TableCell>{entity && entity.name}</TableCell>
                      <TableCell>
                        {entity &&
                          PACKAGE_PLAN_TYPE_VALUE_NAME_MAPPING[entity.type]}
                      </TableCell>
                      <TableCell>
                        {entity.effectiveDate &&
                          moment(entity.effectiveDate).format("YYYY-MM-DD")}
                      </TableCell>
                      <TableCell>
                        {entity.expirationDate &&
                          moment(entity.expirationDate).format("YYYY-MM-DD")}
                      </TableCell>
                      <TableCell>

                        <div className={classes.packageTitle}>Main</div>
                        <Table className={classes.table} size="small" >
                          <TableHead>
                            <TableRow>
                              <TableCell className={classes.textCenter}>Store</TableCell>
                              <TableCell className={classes.textCenter}>Category</TableCell>
                              <TableCell className={classes.textCenter}>Total</TableCell>
                              <TableCell className={classes.textCenter}>Used</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {entity.mainBranch.map(item => (
                              <TableRow hover key={item.offeringCategoryName}>
                                <TableCell className={classes.textCenter}>
                                  {item && item.storeName}
                                </TableCell>
                                <TableCell className={classes.textCenter}>
                                  {item && item.offeringCategoryName}
                                </TableCell>
                                <TableCell className={classes.textCenter}>
                                  {entity.originalTicketCount}
                                </TableCell>
                                <TableCell className={classes.textCenter}>
                                  {item && item.calculatedCount}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>

                        {entity.additionalBranch.length > 0 ? (
                          <div>
                            <div className={classes.packageTitle}>Additional</div>
                            <Table className={classes.table} size="small" >
                              <TableHead>
                                <TableRow>
                                  <TableCell className={classes.textCenter}>Store</TableCell>
                                  <TableCell className={classes.textCenter}>Category</TableCell>
                                  <TableCell className={classes.textCenter}>ExchangeRate</TableCell>
                                  <TableCell className={classes.textCenter}>Used</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {entity.additionalBranch.map(item => (
                                  <TableRow hover key={item.offeringCategoryName}>
                                    <TableCell className={classes.textCenter}>
                                      {item && item.storeName}
                                    </TableCell>
                                    <TableCell className={classes.textCenter}>
                                      {item && item.offeringCategoryName}
                                    </TableCell>
                                    <TableCell className={classes.textCenter}>
                                      {item && item.exchangeRate}
                                    </TableCell>
                                    <TableCell className={classes.textCenter}>
                                      {item && item.usedPurchasedPackagePlanItem / (item.exchangeRate ? item.exchangeRate : 1)}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table></div>
                        ) : (<div></div>)}

                        {0 && entity.totalBranch.length > 0 ? (
                          <div>
                            <div className={classes.packageTitle}>Total</div>
                            <Table className={classes.table} size="small" >
                              <TableHead>
                                <TableRow>
                                  <TableCell className={classes.textCenter}>Store</TableCell>
                                  <TableCell className={classes.textCenter}>Category</TableCell>
                                  <TableCell className={classes.textCenter}>Total</TableCell>
                                  <TableCell className={classes.textCenter}>Used</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {entity.totalBranch.map(item => (
                                  <TableRow hover key={item.offeringCategoryName}>
                                    <TableCell className={classes.textCenter}>
                                      {item && item.storeName}
                                    </TableCell>
                                    <TableCell className={classes.textCenter}>
                                      {item && item.offeringCategoryName}
                                    </TableCell>
                                    <TableCell className={classes.textCenter}>
                                      {entity.originalTicketCount}
                                    </TableCell>
                                    <TableCell className={classes.textCenter}>
                                      {item && item.usedTicketCount}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table></div>
                        ) : (<div></div>)}


                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={totalCount}
            onChangePage={handlePageNumberChange}
            onChangeRowsPerPage={handlePageSizeChange}
            page={pageNumber}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[5, 10, 25, 50]}
          />
        </CardActions>
        {loading && (
          <div className={classes.progress}>
            <CircularProgress />
          </div>
        )}
      </Card>
    </div>
  );
};

export default ListingTable;
