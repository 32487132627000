import React, { useState } from "react";
import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikFormDialog from "../../../components/dialogs/FormikFormDialog";
import FormikOfferingCategorySelectForPackagePlan from "../../../components/inputs/FormikOfferingCategorySelectForPackagePlan";
import AutoCompleteOffTextField from "../../../components/inputs/AutoCompleteOffTextField";
import FormikStoreBranchSelect from "../../../components/inputs/FormikStoreBranchSelect";
import { useFormikContext } from "formik";
import { createFilterOptions } from "@material-ui/lab";

export default function (props) {
  const { index, open, onClose, title, loading, item, items, ...rest } = props;

  const { values, touched, errors, setFieldValue, setFieldTouched } = useFormikContext();

  const [disabledCategorySelect, setDisabledCategorySelect] = useState(true);
  if (item?.type === "ADDITIONAL") {
    delete item["ticketCount"];
  }

  let itemOfferingCategoryIds = [];
  let itemStoreBranchIds = [];
  let removeMainOfferCategory = false;

  let mainItem = items.filter(entry => entry.type === "MAIN")[0];
  if (index > 0 || item?.type === "ADDITIONAL") {
    if (mainItem?.offeringCategory?.name === "ALL") {
      itemStoreBranchIds.push(mainItem.applicableStoreBranch.id);
    } else if (mainItem?.applicableStoreBranch.id === values.applicableStoreBranch?.id) {
      removeMainOfferCategory = true;
    }
  }

  if (item?.applicableStoreBranch.id === values.applicableStoreBranch?.id) {
    if (item?.offeringCategory) {
      itemOfferingCategoryIds.push(item.offeringCategory.id);
    }
  }

  if (values.applicableStoreBranch && items) {
    itemOfferingCategoryIds = itemOfferingCategoryIds.concat(
      items.map(item => {
        if (item.applicableStoreBranch.id === values.applicableStoreBranch.id) {
          return item.offeringCategory.id;
        }
      })
    );
  }

  const defaultFilterOptions = createFilterOptions({
    stringify: option => option.name
  });

  const filterStoreBranchOptions = (options, state) => {
    const candidateOptions = options.filter(option => !itemStoreBranchIds.includes(option.id));

    return defaultFilterOptions(candidateOptions, state);
  };

  const filterOfferingCategoryOptions = (options, state) => {
    let candidateOptions = options.filter(option => !itemOfferingCategoryIds.includes(option.id));
    if (removeMainOfferCategory) {
      candidateOptions = candidateOptions.filter(option => option.name !== "ALL");
    }
    return defaultFilterOptions(candidateOptions, state);
  };

  return (
    <FormikFormDialog {...rest} title={title} open={open} loading={loading} onClose={onClose}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {index > 0 || (item && item.type === "ADDITIONAL") ? "ADDTIONAL PACKAGE ITEM" : "MAIN PACKAGE ITEM"}
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <FormikStoreBranchSelect
            fullWidth
            name="applicableStoreBranch"
            label="Store Branch"
            margin="dense"
            variant="outlined"
            filterOptions={filterStoreBranchOptions}
            disabled = {item}
          />
        </Grid>
        <Grid item xs={4}>
          <FormikOfferingCategorySelectForPackagePlan
            required
            fullWidth
            name="offeringCategory"
            label="Class Category"
            margin="dense"
            variant="outlined"
            filterOptions={filterOfferingCategoryOptions}
            disabled={!values.applicableStoreBranch || item}
          />
        </Grid>
        <Grid item xs={4}>
          {index > 0 || item?.type === "ADDITIONAL" ? (
            <FormikTextField
              required
              fullWidth
              label="Exchange Rate"
              name="exchangeRate"
              variant="outlined"
              margin="dense"
              component={AutoCompleteOffTextField}
              disabled={!values.offeringCategory}
            />
          ) : (
              <FormikTextField
                required
                fullWidth
                label="Ticket Count"
                name="ticketCount"
                variant="outlined"
                margin="dense"
                component={AutoCompleteOffTextField}
                disabled={!values.offeringCategory}
              />
            )}
        </Grid>
      </Grid>
    </FormikFormDialog>
  );
}

export const validationSchema = Yup.object().shape({
  applicableStoreBranch: Yup.object()
    .nullable(true)
    .required("Required"),
  offeringCategory: Yup.object()
    .nullable(true)
    .required("Required"),
  ticketCount: Yup.number()
    .typeError("Must be an integer")
    .integer("Can only be integers")
    .positive("Must be positive")
});

export const initialValues = {
  type: "MAIN",
  offeringCategory: null,
  applicableStoreBranch: null
};
