import { Formik, useFormikContext } from "formik";
import Grid from "@material-ui/core/Grid";
import React from "react";
import FilterFormButtonGroup from "../../../components/filters/FilterFormButtonGroup";
import FormikInstructorSelect from "../../../components/inputs/FormikInstructorSelect";

const FilterForm = props => {
  const { loading } = props;

  const { handleSubmit } = useFormikContext();

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item md={3} xs={6}>
          <FormikInstructorSelect
            fullWidth
            label="Instructor"
            name="instructor"
            variant="outlined"
            margin="dense"
          />
        </Grid>
        <Grid item md={9} xs={6}>
          <FilterFormButtonGroup loading={loading} align="left" />
        </Grid>
      </Grid>
    </form>
  );
};

export default function(props) {
  const { loading, onSubmit } = props;

  return (
    <Formik
      initialValues={{
        instructor: null
      }}
      onSubmit={onSubmit}
    >
      <FilterForm loading={loading} />
    </Formik>
  );
}
